<template>
  <div class="container">
    <memlist-modal
      size="lg"
      ref="edit-form"
      :visible="show_modal"
      @close="show_modal = false"
      hide-footer
    >
      <!-- Invoice ID (read-only if it exists) -->
      <memlist-text-input
        v-if="local_item.invoice_id"
        id="invoice_id"
        name="invoice_id"
        :title="$t('INVOICE.INVOICE_ID')"
        layout="vertical"
        v-model="local_item.invoice_id"
        disabled
      />

      <!-- Created At -->
      <memlist-text-input
        id="created_at"
        name="created_at"
        :title="$t('INVOICE.CREATED_AT')"
        layout="vertical"
        v-model="local_item.created_at"
        disabled
      />

      <!-- Due Date -->
      <memlist-text-input
        id="due_date"
        name="due_date"
        :title="$t('INVOICE.DUE_DATE')"
        layout="vertical"
        v-model="local_item.due_date"
        disabled
      />

      <!-- Amount Paid -->
      <memlist-text-input
        id="amount_paid"
        name="amount_paid"
        :title="$t('INVOICE.AMOUNT_PAID')"
        layout="vertical"
        v-model="local_item.amount_paid"
        type="number"
        disabled
      />

      <!-- Payment Status -->
      <memlist-select-input2
        id="payment_status"
        name="payment_status"
        :title="$t('INVOICE.PAYMENT_STATUS')"
        layout="vertical"
        v-model="local_item.payment_status"
        :items="payment_status_options"
        disabled
      />

      <!-- Paid At -->
      <memlist-text-input
        id="paid_at"
        name="paid_at"
        :title="$t('INVOICE.PAID_AT')"
        layout="vertical"
        v-model="local_item.paid_at"
        disabled
      />


      <!-- Amount -->
      <memlist-text-input
        id="amount"
        name="amount"
        :title="$t('INVOICE.AMOUNT')"
        layout="vertical"
        v-model="local_item.amount"
        disabled
      />

      <!-- Amount VAT -->
      <memlist-text-input
        id="amount_vat"
        name="amount_vat"
        :title="$t('INVOICE.AMOUNT_VAT')"
        layout="vertical"
        v-model="local_item.amount_vat"
        disabled
      />

      <!-- Payment Days -->
      <memlist-text-input
        id="payment_days"
        name="payment_days"
        :title="$t('INVOICE.PAYMENT_DAYS')"
        layout="vertical"
        v-model="local_item.payment_days"
        disabled
      />

      <!-- Number of Reminders -->
      <memlist-text-input
        id="num_reminders"
        name="num_reminders"
        :title="$t('INVOICE.NUM_REMINDERS')"
        layout="vertical"
        v-model="local_item.num_reminders"
        disabled
      />

      <!-- Terms -->
      <memlist-textarea
        id="terms"
        name="terms"
        :title="$t('INVOICE.TERMS')"
        layout="vertical"
        v-model="local_item.terms"
        :autoexpand="true"
        type="textarea"
        disabled
      />

      <!-- OCR -->
      <memlist-text-input
        id="ocr"
        name="ocr"
        :title="$t('INVOICE.OCR')"
        layout="vertical"
        v-model="local_item.ocr"
        disabled
      />

      <!-- Member ID -->
      <memlist-text-input
        id="member_id"
        name="member_id"
        :title="$t('INVOICE.MEMBER_ID')"
        layout="vertical"
        v-model="local_item.member_id"
        disabled
      />

      <!-- Company ID (Sender) -->
      <memlist-text-input
        id="company_id"
        name="company_id"
        :title="$t('INVOICE.COMPANY_ID')"
        layout="vertical"
        v-model="local_item.company_id"
        disabled
      />

      <!-- Receiver Company ID -->
      <memlist-text-input
        id="receiver_company_id"
        name="receiver_company_id"
        :title="$t('INVOICE.RECEIVER_COMPANY_ID')"
        layout="vertical"
        v-model="local_item.receiver_company_id"
        disabled
      />

      <!-- Period ID -->
      <memlist-text-input
        id="period_id"
        name="period_id"
        :title="$t('INVOICE.PERIOD_ID')"
        layout="vertical"
        v-model="local_item.period_id"
        disabled
      />

      <!-- Shop Order ID -->
      <memlist-text-input
        id="shop_order_id"
        name="shop_order_id"
        :title="$t('INVOICE.SHOP_ORDER_ID')"
        layout="vertical"
        v-model="local_item.shop_order_id"
        disabled
      />

      <!-- File ID -->
      <memlist-text-input
        id="file_id"
        name="file_id"
        :title="$t('INVOICE.FILE_ID')"
        layout="vertical"
        v-model="local_item.file_id"
        disabled
      />

      <!-- Deleted -->
      <memlist-select-input2
        id="deleted"
        name="deleted"
        :title="$t('INVOICE.DELETED')"
        layout="vertical"
        v-model="local_item.deleted"
        :items="boolean_options"
        disabled
      />

      <!-- Printed -->
      <memlist-select-input2
        id="printed"
        name="printed"
        :title="$t('INVOICE.PRINTED')"
        layout="vertical"
        v-model="local_item.printed"
        :items="boolean_options"
        disabled
      />

      <!-- Country -->
      <memlist-text-input
        id="country"
        name="country"
        :title="$t('INVOICE.COUNTRY')"
        layout="vertical"
        v-model="local_item.country"
        disabled
      />

      <!-- Message -->
      <memlist-textarea
        id="message"
        name="message"
        :title="$t('INVOICE.MESSAGE')"
        layout="vertical"
        v-model="local_item.message"
        :autoexpand="true"
        type="textarea"
        disabled
      />

      <!-- Linkstr -->
      <memlist-text-input
        id="linkstr"
        name="linkstr"
        :title="$t('INVOICE.LINKSTR')"
        layout="vertical"
        v-model="local_item.linkstr"
        disabled
      />

      <!-- Currency -->
      <memlist-select-input2
        id="currency"
        name="currency"
        :title="$t('INVOICE.CURRENCY')"
        layout="vertical"
        v-model="local_item.currency"
        :items="currency_options"
        disabled
      />

      <!-- Invoice Template ID -->
      <memlist-text-input
        id="invoice_template_id"
        name="invoice_template_id"
        :title="$t('INVOICE.INVOICE_TEMPLATE_ID')"
        layout="vertical"
        v-model="local_item.invoice_template_id"
        disabled
      />

      <!-- Invoice Status -->
      <memlist-select-input2
        id="status"
        name="status"
        :title="$t('INVOICE.STATUS')"
        layout="vertical"
        v-model="local_item.status"
        :items="invoice_status_options"
        disabled
      />

      <!-- Kivra ID -->
      <memlist-text-input
        id="kivra_id"
        name="kivra_id"
        :title="$t('INVOICE.KIVRA_ID')"
        layout="vertical"
        v-model="local_item.kivra_id"
        disabled
      />

      <!-- Kivra Status -->
      <memlist-select-input2
        id="kivra_status"
        name="kivra_status"
        :title="$t('INVOICE.KIVRA_STATUS')"
        layout="vertical"
        v-model="local_item.kivra_status"
        :items="kivra_invoice_status_options"
        disabled
      />

      <RightModalSaveAndCloseButtons
        class="mt-4"
        :text="$t('COMMON.SAVE')"
        :spin="true"
        @clicked="save"
        @close="show_modal = false"
        ref="saveButton"
      />
    </memlist-modal>
  </div>
</template>

<script>
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import { mapGetters } from 'vuex';
import axios from 'axios';
import RightModalSaveAndCloseButtons from '@/view/components/buttons/RightModalSaveAndCloseButtons.vue';

export default {
  name: 'InvoiceEditorModal',
  props: ['item'],
  mixins: [ toasts ],
  components: {
    RightModalSaveAndCloseButtons,
  },
  watch: {
    item: {
      handler(val) {
        this.local_item = { ...val };
      },
      immediate: true
    }
  },
  computed: {
    ...mapGetters(['currentCompanyId']),
    payment_status_options() {
      return [
        { text: this.$t('INVOICE.PAYMENT_STATUSES.LEGACY'), value: 'LEGACY' },
        { text: this.$t('INVOICE.PAYMENT_STATUSES.FREE'), value: 'FREE' },
        { text: this.$t('INVOICE.PAYMENT_STATUSES.NOT_PAID'), value: 'NOT_PAID' },
        { text: this.$t('INVOICE.PAYMENT_STATUSES.PARTIALLY_PAID'), value: 'PARTIALLY_PAID' },
        { text: this.$t('INVOICE.PAYMENT_STATUSES.PAID_IN_FULL'), value: 'PAID_IN_FULL' },
        { text: this.$t('INVOICE.PAYMENT_STATUSES.REFUND'), value: 'REFUND' },
      ];
    },
    boolean_options() {
      return [
        { text: this.$t('COMMON.FALSE'), value: false },
        { text: this.$t('COMMON.TRUE'), value: true }
      ];
    },
    currency_options() {
      return [
        { text: 'SEK', value: 'SEK' },
        { text: 'USD', value: 'USD' },
        { text: 'EUR', value: 'EUR' }
      ];
    },
    invoice_status_options() {
      return [
        { text: this.$t('INVOICE.STATUSES.NONE'), value: 'NONE' },
        { text: this.$t('INVOICE.STATUSES.PENDING_SEND'), value: 'PENDING_SEND' },
        { text: this.$t('INVOICE.STATUSES.WAITING_PAYMENT'), value: 'WAITING_PAYMENT' },
        { text: this.$t('INVOICE.STATUSES.PAID'), value: 'PAID' },
        { text: this.$t('INVOICE.STATUSES.DELETED'), value: 'DELETED' },
      ];
    },
    kivra_invoice_status_options() {
      return [
        { text: this.$t('INVOICE.KIVRA_STATUSES.NONE'), value: 'NONE' },
        { text: this.$t('INVOICE.KIVRA_STATUSES.CREATED'), value: 'CREATED' },
        { text: this.$t('INVOICE.KIVRA_STATUSES.FAILED'), value: 'FAILED' },
        { text: this.$t('INVOICE.KIVRA_STATUSES.NOT_SSN'), value: 'NOT_SSN' }
      ];
    }
  },
  methods: {
    async save() {
      this.$refs['saveButton'].start();
      /*try {
        if (!this.local_item.invoice_id) {
          // Create new invoice
          const res = await axios.post(`/invoice`, this.local_item);
          if (res.status === 201) {
            this.local_item = res.data;
            this.$emit('created', this.local_item);
          } else {
            this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('INVOICE.UNABLE_CREATE'));
          }
        } else {
          // Update existing invoice
          const res = await axios.put(`/invoice/${this.local_item.invoice_id}`, this.local_item);
          if (res.status === 200) {
            this.$emit('updated', this.local_item);
          } else {
            this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('INVOICE.UNABLE_UPDATE'));
          }
        }
      } catch (error) {
        this.toastr('danger', this.$t('COMMON.ERROR'), error.message);
      }*/
      this.$refs['saveButton'].stop();
      this.hide();
    },
    show() {
      this.show_modal = true;
    },
    hide() {
      this.show_modal = false;
    }
  },
  data() {
    return {
      local_item: {},
      show_modal: false
    };
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/sass/components/forms/_memlist_table.scss";
@import "@/assets/sass/components/forms/_common_modals.scss";
</style>
